import { React } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav/Nav";
import Header from "./components/Header/Header";
import About from "./components/About/About";
// import Services from './components/Services/services';
import Background from "./components/background/background";
import Form from "./components/Form/Form";
import "./App.css";

function App() {
  return (
    <Router>
    <div className="App">
      <Background/>
      <div className='content'>
        <Nav className="nav" />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header className="header" />
                <About className="about"></About>
                <Form className="contact_form" />
              </>
            }
          />
          <Route path="/about" element={<About className="about"/>}/>
          <Route path="/contact" element={<Form className="contact_form"/>}/>
        </Routes>
      </div>
      </div>
    </Router>
  );
}

export default App;
