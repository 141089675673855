import React, { useState } from "react";
import axios from "axios";
import "./Form.css";

function Form() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(""); // New state for phone number
  const [message, setMessage] = useState("");
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!name || !email || !message || !phoneNumber) {
      alert("Fill in all the fields to continue");
      return;
    }

    try {
      await axios.post("https://us-central1-absolute-education-website.cloudfunctions.net/sendEmail", {
        name,
        email,
        phoneNumber,
        message,
        howDidYouHearAboutUs,
      });
      alert("Message Sent!");
    } catch (error) {
      alert("Try Again!");
    }
}
  return (
    <div className="formBody">
      <div className="body">
        <div>
          <h2 className="h2">Contact Us</h2>
          <h3 className="h3">
            Please fill out the form below to get in touch with us.
          </h3>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <label htmlFor="name" className="label">
            Name:
            <input
              id="name"
              className="input"
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </label>
          <label htmlFor="email" className="label">
            Email:
            <input
              id="email"
              className="input"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </label>
          <label htmlFor="phoneNumber" className="label">
            Phone Number:
            <input
              id="phoneNumber"
              className="input"
              type="tel"
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
            />
          </label>
          <label htmlFor="aboutUs" className="label">
            How did you hear about us?
            <select
              id="aboutUs"
              className="input"
              value={howDidYouHearAboutUs}
              onChange={(event) => setHowDidYouHearAboutUs(event.target.value)}
            >
              <option value="">Select...</option>
              <option value="Google">Google</option>
              <option value="Facebook">Facebook</option>
              <option value="Friend">Friend</option>
              <option value="Advertisement">Advertisement</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <label htmlFor="message" className="label-textarea">
            Message:
            <textarea
              id="message"
              className="textarea"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            ></textarea>
          </label>
          <button className="button2" type="submit">
            Send
          </button>
        </form>
      </div>
      <div className="location">
        <div className="location-container">
          <p className="location__text"> LOCATION</p>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3101.1927017465!2d-77.48500072405882!3d38.98809817170483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b63f456f593483%3A0x42d2b6bcadb1cd36!2s22718%20Dexter%20House%20Terrace%2C%20Ashburn%2C%20VA%2020148!5e0!3m2!1sen!2sus!4v1696813006634!5m2!1sen!2sus"
            width="600"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Form;
