import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Nav.css';

export default function Nav() {
    const [showLinks, setShowLinks] = useState(false);
    const menuBackgroundRef = useRef(null);

    const toggleLinks = () => {
        if (window.innerWidth <= 768) {
            setShowLinks(!showLinks);

            if (!showLinks) {
                disableScroll();
            } else {
                enableScroll();
            }
        }
    };

    const handleClickOutside = () => {
        setShowLinks(false);
        enableScroll();
    };

    const disableScroll = () => {
        document.body.style.overflow = "hidden";
    };

    const enableScroll = () => {
        document.body.style.overflow = "auto";
    };

    return (
        <nav className="app-nav">
            {showLinks && (
                <div
                    className="menu-background"
                    ref={menuBackgroundRef}
                    onClick={handleClickOutside}
                ></div>
            )}
            <div className="nav-container">
                <img
                    src="navlogo.jpg"
                    alt="absolute-educationlogo"
                    aria-label="Menu toggle"
                    className="logo"
                    onClick={toggleLinks}
                />
                <ul className={`nav-links${showLinks ? ' show' : ''}`}>
                    <li>
                        <Link className="nav-link" to="/" onClick={() => { setShowLinks(false); enableScroll(); }}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link className="nav-link" to="/about" onClick={() => { setShowLinks(false); enableScroll(); }}>
                            About
                        </Link>
                    </li>
                    <li>
                        <Link className="nav-link" to="/contact" onClick={() => { setShowLinks(false); enableScroll(); }}>
                            Contact 
                        </Link>
                    </li>
                </ul>
                <h1 className="nav-title-small">ABSOLUTE EDUCATION</h1>
            </div>
        </nav>
    );
}
