import React from 'react';
import './Header.css';
import 'animate.css'

function Header() {
  return (
    <header className="header">
      <div className="header__info">
        <h1 className="header__title  animate__animated animate__fadeInUp">WELCOME!</h1>
        <h2 className="header__subtitle  animate__animated animate__fadeInUp">
        We are a tutoring service based in Ashburn, Virginia.  Our tutor, AJ, has a Bachelor's degree in mathematics from Virginia Tech and over 5 years of tutoring experience. We specialize in advanced math tutoring for the following subjects:
<ul>
        <li>Algebra I</li>
        <li>Geometry </li>
        <li>Algebra II</li>
        <li>Trigonometry</li>
        <li>Precalculus</li>
        <li>Calculus</li>
        <li>Probability and Statistics</li>
        <li>SAT/ACT Math</li>
        </ul>
        We offer flexible scheduling and can meet students at a local library or tutor in our in-home classroom right here in Ashburn!  Contact us today to schedule a session! 
        </h2>
        <br />
        <br />
        <a href="/contact" className="button animate__animated animate__fadeInUp">
          Contact Us
        </a>
      </div>
    </header>
  );
}

export default Header;
