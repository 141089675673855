import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectFade } from "swiper/core";
import "swiper/swiper-bundle.min.css";
import "animate.css";
import "./About.css";

function About() {
  const [showRoomImageModal, setShowRoomImageModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const handleCardClick = () => {
    setShowRoomImageModal(true);
  };

  const closeModal = () => {
    setShowRoomImageModal(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (showRoomImageModal) {
        closeModal();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showRoomImageModal]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>
      <h2 className="about-title animate__animated animate__zoomIn">About</h2>
      <div className="about-swiper">
        <Swiper
          spaceBetween={30}
          effect="fade"
          navigation={true}
          pagination={{ clickable: true }}
          slidesPerView={1}
          centeredSlides={true}
          modules={[EffectFade, Navigation, Pagination]}
          className="about-swiper-inner"
        >
          <SwiperSlide className="image-slide">
            <div className="image-text-wrapper">
              <div className="image-title">Our Mission</div>
              <div className="image-text">
                <p className="p-text-big">
                  "I don’t want a nation of thinkers, I want a nation of
                  workers". That is a quote attributed to John D. Rockefeller,
                  founder of the General Education Board in 1902. While it is
                  unproven whether or not he actually said this, it is an
                  accurate representation of how our current education system
                  feels. The environment of government mandated minimal
                  education requirements, standardized testing, and minimized
                  student responsibility does nothing to foster students'
                  curiosity about learning. At Absolute Education, we encourage
                  students to have an inquisitive and self-driven mindset. We
                  firmly believe that by aspiring to be thinkers and developing
                  a strong learning skillset, students will be happier, more
                  fulfilled, and more productive throughout their entire lives.
                </p>
              </div>
            </div>
            <img
              className="image-brain"
              src="asset5.png"
              alt="Absolute Education Logo"
            />
          </SwiperSlide>
          {/* Comment out for now */}
{/*
<SwiperSlide className="image-slide">
  <img
    className="image-why"
    src="asset4.png"
    alt="Absolute Education Logo"
  />
  <div className="image-text-wrapper">
    <div className="image-title">Why Semi-Private Tutoring?</div>
    <div className="image-text">
      <p className="p-text">
        Tutoring is expensive, and in our experience a private
        one-on-one tutoring session can have a lot of downtime as the
        student is completing problems or working on assignments.
        Instead of paying a private tutoring rate just to have
        downtime, we have found that a 3:1 student to teacher ratio
        allows the tutor to give excellent attention to every student
        at a more affordable hourly rate.
      </p>
    </div>
  </div>
</SwiperSlide>
*/}
{/* Comment ends here */}
          <SwiperSlide className="image-slide" onClick={handleCardClick}>
            <img
              className="room-image"
              src="RoomPicture.jpg"
              alt="Room, clean and tidy"
            />
            <div className="image-text-wrapper">
              <div className="image-title specific-title">The Classroom</div>
              <div className="image-text">
                <p className="p-text">
                  We have a portion of our home dedicated solely for tutoring.
                  This includes a space with the tutor and students' desks, an
                  office for administrative work, a waiting area, and a
                  restroom. Wi-fi is available, as well as a printer for
                  worksheets and other resources.
                </p>
              </div>
            </div>
          </SwiperSlide>
          {
            isMobile && (
                <SwiperSlide className="image-slide-mobile" onClick={handleCardClick}>
                    <img
                        className="room-image-mobile"
                        src="RoomPicture.jpg"
                        alt="Room, clean and tidy"
                    />
                </SwiperSlide>
            )
        }
        </Swiper>
        {showRoomImageModal && (
          <div className="room-image-modal">
            <div className="modal-background" onClick={closeModal}></div>
            <img className="modal-room-image" src="RoomPicture.jpg" alt="Room, clean and tidy" />
          </div>
        )}
      </div>
    </div>
  );
}

export default About;
