import React from "react";

import './background.css';

function Background() {
    return (
        <video autoPlay loop muted className="video-background">
            <source src="header.mov" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
}

export default Background;
